import {Component, Input, OnInit} from '@angular/core';
import {WITHDRAW_TRANSACTION_TYPES} from "../../config/withdraw/transactionsTypes.config";
import {TRANSACTIONS_LIST_TYPE} from "./senetpro-transactions.model";
import {CurrencyService} from "../../modules/currency/currency.service";

@Component({
    selector: 'app-senetpro-transactions',
    templateUrl: './senetpro-transactions.component.html',
    styleUrls: ['./senetpro-transactions.component.scss']
})
export class SenetproTransactionsComponent implements OnInit {
    @Input() items = [];
    @Input() processingItems = [];
    @Input() isPerks = false;
    @Input() isBonus = false;
    @Input() type = TRANSACTIONS_LIST_TYPE.transactions;
    @Input() isStickyHeader = true;

    bonusTypes = {
      "0": "Allocated",
      "1": "Withdraw"
    }

    WITHDRAW_TRANSACTION_TYPES = WITHDRAW_TRANSACTION_TYPES;
    TRANSACTIONS_LIST_TYPE = TRANSACTIONS_LIST_TYPE;
    constructor(private currencyService: CurrencyService) {}

    ngOnInit() {}

    isTransaction(cellType: number, dataType: number): boolean {
        return cellType === dataType || dataType === this.WITHDRAW_TRANSACTION_TYPES.bonuses;
    }

    getDate(date) {
        if(date == null){
            return '';
        }
        return date.replace(/\s/g, 'T');
    }

    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount);
    }

  getBonusType(type) {
      return this.bonusTypes[type];
  }

  formatBonuses(amount) {
      return this.currencyService.formatBonuses(amount);
  }
}
