<div class="login page-wrapper">
    <div class="login__inner" [class.has-back]="isPinClassActive()"  [class.is-blurred]="menu || contactUsPopup.show">
        <button *ngIf="isPinClassActive()" (click)="goBackToLogin()" class="login__back">
            <span class="visually-hidden">{{'btnBack' | languageTranslate}}</span>
        </button>
        <div class="login__col login__top">
            <div class="login__header">
                <img class="login__logo" *ngIf="getPartnerLogo()" src="{{getPartnerLogo()}}" alt="">
            </div>

            <div class="login__title-wrapper">
<!--                <div class="login__title">-->
<!--                    <span class="login__title&#45;&#45;inner">-->
<!--                        <span class="capital">{{('pageSubtitle' | languageTranslate).charAt(0)}}</span>{{('pageSubtitle' | languageTranslate).substr(1)}}-->
<!--                    </span>-->
<!--                </div>-->
                <div *ngIf="isRegistrationClassActive()" class="login__title">
                    {{'imaginationDrivenArt' | languageTranslate}}
                </div>
            </div>

        </div>

        <div class="login__col" [class.login__col--register]="isRegistrationClassActive()">
            <div *ngIf="isRegistrationClassActive() && isLoginTypePhone() && !isRegistrationUpdate" class="login__text login__text--mb-sm">
<!--                Hi there! Thank you for choosing {{getPartnerName()}}. You are registering with phone number:-->
                {{'registeredMessage' | languageTranslate: [{partnerName: getPartnerName()}]}}
                <div class="accent">{{phoneForm.controls.phone.value | mask: phoneMask}}</div>
            </div>


            <div class="login__forms">
                <div *ngIf="isPhoneClassActive() && !terminalConnectingPopup" class="text-center login-tab">
                    <form [formGroup]="phoneForm" (ngSubmit)="onPhoneSubmit()" class="login__form">
                        <div class="login__item login__item--row">
                            <input *ngIf="isLoginTypePhone()"
                                   [mask]="phoneMask"
                                   formControlName="phone"
                                   placeholder="{{'phoneNumber' | languageTranslate}}"
                                   [type]="'tel'"
                                   class="login-input js-phone-input"
                                   autocomplete="off">
                            <input *ngIf="isLoginTypeEmail()"
                                   formControlName="email"
                                   placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}"
                                   [email]="true"
                                   [type]="'text'"
                                   class="login-input js-phone-input"
                                   autocomplete="off">
                            <div class="login__login-type-buttons">
                                <button *ngIf="isLoginTypeEmail()"
                                        (click)="changeLoginType('phone')">
                                    <svg>
                                        <use xlink:href="../../../../assets/images/sprite.svg#phone"></use>
                                    </svg>
                                </button>
                                <button *ngIf="isLoginTypePhone()"
                                        type="button"
                                        (click)="changeLoginType('email')">
                                    <svg>
                                        <use xlink:href="../../../../assets/images/sprite.svg#email"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div class="login__item">

                            <input formControlName="remember_user"
                                   id="rememberUser"
                                   [type]="'checkbox'"
                                   class="login-checkbox"
                                   (change)="onRememberUser($event)"
                                   [checked]="!!phoneForm.controls.remember_user.value"
                                   autocomplete="off">
                            <label for="rememberUser">{{'rememberUsername' | languageTranslate}}</label>
                        </div>
                        <div class="form-btn-submit">
                            <button id="login_btn"
                                    class="button snake-btn is-notouched"
                                    [disabled]="isLoginBtnDisabled()">
                                {{'next' | languageTranslate}}
                                <svg><rect></rect></svg>
                            </button>

                            <button type="button" class="button snake-btn btn-login-help"
                                    style="margin-left: auto; margin-right: auto;"
                                    (click)="contactUs()">
                                <svg class="visible">
                                    <use xlink:href="../../../../assets/images/sprite.svg#info"></use>
                                </svg>
                                {{'login' | languageTranslate}} {{'help' | languageTranslate}}
<!--                                <svg><rect></rect></svg>-->
                            </button>
                        </div>
                    </form>

                    <div *ngIf="!isInStandaloneMode()">
                        <!--            Android-->
                        <button *ngIf="androidInstallBtn && !isIos()" class="button snake-btn is-notouched" (click)="install()">
                            {{'install' | languageTranslate}}
                            <svg><rect></rect></svg>
                        </button>

                        <!--            IOS-->
                        <button *ngIf="!IOSInstallBtn && isIos()" class="button snake-btn is-notouched" (click)="install()">
                            {{'install' | languageTranslate}}
                            <svg><rect></rect></svg>
                        </button>

                        <div id="iosInstall" *ngIf="IOSInstallBtn" class="info">
                            <div class="info__pre">
                                <img src="../../../../assets/images/add-icon.svg" alt="{{'installData' | languageTranslate: [{$innerVariable: 'addImgAlt'}]}}">
                            </div>
                            <div class="info__body">
                                <p  id="infoText">{{ios_popup_text}}</p>
                                <p> {{'installData' | languageTranslate: [{$innerVariable: 'firstIOSInstallStep'}]}}
                                    <img src="../../../../assets/images/share-icon.svg" alt="{{'installData' | languageTranslate: [{$innerVariable: 'shareImgAlt'}]}}">
                                </p>
                                <p>{{'installData' | languageTranslate: [{$innerVariable: 'secondIOSInstallStep'}]}}.</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div *ngIf="isPinClassActive()" class="login-tab">
                    <div *ngIf="!isRegistrationClassActive()" class="login-content login-content--mt">
                        <p class="text t-light login__text" *ngIf="isSecondStepAuthSMSPin()">
<!--                            Enter the four digit pin sent to the number ending in -{{phoneLastFour}}.-->
                            {{'loginSecondStepInfo' | languageTranslate: [{phoneLastFour: phoneLastFour}]}}
                        </p>
                        <p class="text t-light login__text"
                           *ngIf="isSecondStepAuthAuthPin()">
                            <!--  Enter the four digit pin sent to the number ending in -{{phoneLastFour}}.-->
                            {{'authPinTitle' | languageTranslate}}
                        </p>
                        <p class="text t-light login__text"
                           *ngIf="isSecondStepPassword() || isSecondStepAuthAuthPinPassword()">
                            {{'passwordTitle' | languageTranslate}}
                        </p>
                    </div>
                    <div *ngIf="isRegistrationClassActive()" class="login-content">
<!--                        <p class="text t-light login__text login__text&#45;&#45;md">-->
<!--                            {{'registerSecondStepInfo' | languageTranslate}}-->
<!--                        </p>-->

                        <app-login-contacts-form (registerUser)="onRegisterNewUser($event)"
                                                 [userInfoUpdateData]="userRegistrationUpdateData"
                                                 [isUserInfoUpdate]="isRegistrationUpdate"
                                                 (backToLogin)="goBackToLogin()"
                                                 [email]="phoneForm.controls.email.value"></app-login-contacts-form>
                    </div>
                    <form  *ngIf="!isRegistrationClassActive()"
                           [formGroup]="pinForm"
                           (ngSubmit)="onSubmit()"
                           class="login-page__form">
                        <div class="login__items"
                             formArrayName="digits"
                             *ngIf="isSecondStepAuthAuthPin() || isSecondStepAuthSMSPin()">
                            <input (keydown.enter)="onSubmit()"
                                   *ngFor="let field of pinForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i"  (keydown)="check(i, field, $event)"
                                   mask="0"
                                   class="login-input-small" type="tel">
                        </div>

                        <div *ngIf="isSecondStepPassword() || isSecondStepAuthAuthPinPassword() || isSecondStepAuthSMSPinPassword()"
                             class="login__item" [ngStyle]="{marginBottom: isSecondStepPassword() ? '2rem' : 0}">
                            <input formControlName="password"
                                   style="margin: 0"
                                   placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                                   [type]="'password'"
                                   class="login-input js-phone-input"
                                   autocomplete="off">
                            <button class="forgot-password"
                                    (click)="onForgotPassword()"
                                    type="button">
                                {{'forgotPasswordTitle' | languageTranslate}}
                            </button>
                        </div>

                        <button *ngIf="isSecondStepAuthSMSPin()"
                                (click)="resendSms()" type="button" class="text t-light login-text login__resend login__resend--no-mb">
                            {{'resend' | languageTranslate}}
                        </button>

                        <button *ngIf="isSecondStepAuthAuthPinPassword() || isSecondStepAuthAuthPin() || isSecondStepAuthSMSPin() || isSecondStepAuthSMSPinPassword()"
                                (click)="toggleInputType()"
                                type="button" class="text t-light login-text login__resend">
                            <ng-container *ngIf="isSecondStepAuthAuthPin() || isSecondStepAuthSMSPin()">
                                {{'usePassword' | languageTranslate}}
                            </ng-container>
                            <ng-container *ngIf="isSecondStepAuthAuthPinPassword()">
                                {{'usePin' | languageTranslate}}
                            </ng-container>
                            <ng-container *ngIf="isSecondStepAuthSMSPinPassword()">
                                {{'usePin' | languageTranslate}}
                            </ng-container>
                        </button>

                        <div class="text-center form-btn-submit">
                            <button type="submit" id="verify" class="button snake-btn is-notouched"
                                    [disabled]="((isSecondStepAuthSMSPin() || isSecondStepAuthAuthPin()) && !pinForm.controls.digits.valid) ||
                                                ((isSecondStepPassword() || isSecondStepAuthAuthPinPassword()) && !pinForm.controls.password.value)">
                                <ng-container *ngIf="isSecondStepAuthSMSPin()">
                                    {{'verify' | languageTranslate}}
                                </ng-container>
                                <ng-container *ngIf="!isSecondStepAuthSMSPin()">
                                    {{'login' | languageTranslate}}
                                </ng-container>
                                <svg><rect></rect></svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="login__disclaimer">
            {{'authFooterInfo' | languageTranslate}}
        </div>

        <svg class="login__decor-bottom" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)" />
            <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)" />
            <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)" />
            <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
            <line x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
        </svg>

        <div class="menu" [class.is-active]="menu">
            <button class="menu__btn menu__btn--menu" (click)="menu = true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.938 37">
                    <path fill-rule="evenodd" d="M161.929,557.994H139.265a1,1,0,0,1,0-2h22.664A1,1,0,0,1,161.929,557.994Zm0-7H139.265a1,1,0,0,1,0-2h22.664A1,1,0,0,1,161.929,551Zm0-7H139.265a1,1,0,0,1,0-2h22.664A1,1,0,0,1,161.929,543.993Zm0-7H139.265a1,1,0,0,1,0-2h22.664A1,1,0,0,1,161.929,537Zm0-7H139.265a1,1,0,0,1,0-2h22.664A1,1,0,0,1,161.929,529.993ZM133.988,565h-5a2,2,0,0,1-2-2v-5a2,2,0,0,1,2-2h5a2,2,0,0,1,2,2v5A2,2,0,0,1,133.988,565Zm0-7h-5v5h5v-5Zm0-7h-5a2,2,0,0,1-2-2v-5a2,2,0,0,1,2-2h5a2,2,0,0,1,2,2v5A2,2,0,0,1,133.988,551Zm0-7h-5v5h5v-5Zm0-7h-5a2,2,0,0,1-2-2v-5a2,2,0,0,1,2-2h5a2,2,0,0,1,2,2v5A2,2,0,0,1,133.988,537Zm0-7h-5v5h5v-5Zm5.277,33h22.664a1,1,0,0,1,0,2H139.265A1,1,0,0,1,139.265,563Z" transform="translate(-127 -528)"/>
                </svg>
            </button>
        </div>

    </div>
    <div class="addition-menu" [class.is-active]="menu">
        <ul class="addition-menu__list">
            <li class="addition-menu__item">
                <button (click)="openAboutSite()">
<!--                    {{'aboutSenet' | languageTranslate}}-->
                    {{'about' | languageTranslate}} {{getPartnerName()}}
                </button>
            </li>
            <li class="addition-menu__item">
                <button (click)="upgradeApp()">
                    {{'upgrade' | languageTranslate}}
                </button>
            </li>
            <li class="addition-menu__item">
                <a [routerLink]="RouterPaths.aiTermsAndConditions"
                   (click)="menu = false"
                   href=""> {{'aiTermsOfUsage' | languageTranslate}}</a>
            </li>
            <li class="addition-menu__item">
                <a [routerLink]="RouterPaths.nftTermsAndConditions"
                   (click)="menu = false"
                   href=""> {{'nftTermsOfUsage' | languageTranslate}}</a>
            </li>
            <li class="addition-menu__item">
                <a [routerLink]="RouterPaths.termsAndConditions"
                   (click)="menu = false"
                   href="">
                    {{'termsOfUsage' | languageTranslate}}
                </a>
            </li>
            <li class="addition-menu__item">
                <button (click)="contactUs()">
                    {{'contactUs' | languageTranslate}}
                </button>
            </li>
        </ul>
        <button (click)="menu = false" class="addition-menu__btn">
            {{'close' | languageTranslate}}
        </button>
    </div>
</div>

<app-popup-contact-us [popupInfo]="contactUsPopup"
                      (close)="hideContactUs()"></app-popup-contact-us>
