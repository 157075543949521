import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {PopupComponent} from "../popup/popup.component";
import {SenetproTransactionsModel, TRANSACTIONS_LIST_TYPE} from "../senetpro-transactions/senetpro-transactions.model";
import {PerksService} from "../../service/perks.service";
import {AccountService} from "../../service/account.service";
import {BonusPointService} from "../../service/bonus-point.service";

@Component({
    selector: 'app-popup-senetpro-transactions',
    templateUrl: './popup-senetpro-transactions.component.html',
    styleUrls: ['./popup-senetpro-transactions.component.scss']
})
export class PopupSenetproTransactionsComponent implements OnInit, OnChanges {
    @Input() popupInfo;
    @Input() type = TRANSACTIONS_LIST_TYPE.transactions;
    @Output() close: EventEmitter<boolean> = new EventEmitter();

    TRANSACTIONS_LIST_TYPE = TRANSACTIONS_LIST_TYPE;

    prizesData: PaginationDataModel;

    constructor(private loaderService: LoaderService,
                private perksService: PerksService,
                private accountService: AccountService,
                private bonusPointService: BonusPointService,
                private paginationScrollService: PaginationScrollService) {}

    ngOnInit() {}

    ngOnChanges(changes) {
        if (changes.popupInfo.currentValue.show) {
            this.prizesData = new PaginationData('prizes', '.js-prizes-item', '.js-prizes-parent', this.type === TRANSACTIONS_LIST_TYPE.transactions ? 'accountHistory' : this.type === TRANSACTIONS_LIST_TYPE.bonuses ? 'bonusHistory' : 'products');

            this.getPrizesInfo();
        } else {
            if (this.prizesData) {
                this.paginationScrollService.resetPagination(this.prizesData);
            }

        }
    }

    getPrizesInfo() {
        if (this.type === TRANSACTIONS_LIST_TYPE.transactions) {
            this.perksService.getPageInfo(1, true).subscribe(data => {
                  this.setPerksInfo(data);
              },
              () => { this.loaderService.hideAndShowTryAgain(); },
              () => { this.loaderService.hide(); });
        } else if (this.type === TRANSACTIONS_LIST_TYPE.bonuses) {
          this.bonusPointService.getPageInfo(1).subscribe(data => {
              this.setBonusInfo(data);
            },
            () => { this.loaderService.hideAndShowTryAgain(); },
            () => { this.loaderService.hide(); });
        } else {
            this.accountService.getPageInfo(1, 1, 1,true, false, false).subscribe(data => {
                  this.setPrizesInfo(data);
              },
              () => { this.loaderService.hideAndShowTryAgain(); },
              () => { this.loaderService.hide(); });
        }

    }

    setPrizesInfo(data: {products: [SenetproTransactionsModel?], productsLimit: number, productsTotal: number }): void {
        this.setTransactionsList(data.products, data.productsLimit, data.productsTotal);
    }

    setPerksInfo(data: {accountHistory: [SenetproTransactionsModel?], limit: number, total: number }): void {
        this.setTransactionsList(data.accountHistory, data.limit, data.total);
    }

    setBonusInfo(data: {bonusHistory: [SenetproTransactionsModel?], limit: number, total: number }): void {
      this.setTransactionsList(data.bonusHistory, data.limit, data.total);
    }

    setTransactionsList(history: [SenetproTransactionsModel?], limit: number, total: number): void {
        this.paginationScrollService.resetPagination(this.prizesData);
        document.querySelector('.js-prizes-parent').scrollTop = 0;
        const prizesDataSettings: SetPaginationDataModel = {
            itemsList: history,
            scrollLimit: limit,
            total: total
        };
        this.initScrolledData(this.prizesData, prizesDataSettings);
    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////
    // DETECTING SCROLL
    scroll(e, settings: PaginationDataModel) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this), true);
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page: number, step = 'end', settings: PaginationDataModel): void {
        if (settings.name ===  'prizes') {
            if (this.type === TRANSACTIONS_LIST_TYPE.transactions) {
                this.perksService.getPageInfo(page, true).subscribe(data => {
                      this.paginationScrollService.dataAddingLogic(page, step, data, settings, true)
                  },
                  () => { this.loaderService.hideAndShowTryAgain() },
                  () => { this.loaderService.hide() }
                );
            } else if (this.type === TRANSACTIONS_LIST_TYPE.bonuses) {
              this.bonusPointService.getPageInfo(page).subscribe(data => {
                  this.paginationScrollService.dataAddingLogic(page, step, data, settings, true)
                },
                () => { this.loaderService.hideAndShowTryAgain() },
                () => { this.loaderService.hide() }
              );
            } else {
                this.accountService.getPageInfo(page, 1, 1, true, false, false).subscribe(data => {
                      this.paginationScrollService.dataAddingLogic(page, step, data, settings, true)
                  },
                  () => { this.loaderService.hideAndShowTryAgain() },
                  () => { this.loaderService.hide() }
                );
            }
        }
    }

    closePopup() {
        this.close.emit(true);
    }
}
