import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthConfig } from './auth-config';
import { AUTH_CONFIG, SENETPRO_CONFIG } from '../../../config/api';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./auth-config";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
import * as i4 from "../../../service/loader.service";
import * as i5 from "../../../service/referralBonus.service";
import * as i6 from "../../../service/point.service";
import * as i7 from "../../../shared/popup/popup.component";
import * as i8 from "../../../service/pos-settings.service";
import * as i9 from "../../../service/session/sessionStorage.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(authConfig, http, router, loaderService, referralBonusService, pointService, popupComponent, posSettingsService, sessionStorageService) {
        this.authConfig = authConfig;
        this.http = http;
        this.router = router;
        this.loaderService = loaderService;
        this.referralBonusService = referralBonusService;
        this.pointService = pointService;
        this.popupComponent = popupComponent;
        this.posSettingsService = posSettingsService;
        this.sessionStorageService = sessionStorageService;
        this.config = AUTH_CONFIG;
        this.configSenetPro = SENETPRO_CONFIG;
        // private storage = sessionStorage;
        this.storage = localStorage;
        this.rolesObservable = new EventEmitter();
        this.userLoggedIn = new EventEmitter();
        this.userLoggedStatusChanged = new BehaviorSubject(this.isUserLogged());
    }
    AuthenticationService.prototype.isUserLogged = function () {
        return !!this.storage.getItem('currentUser');
    };
    // checkPhoneAndSendPin(phone: string, country: string){
    //     this.loaderService.show();
    //     return this.http.post<any>(this.config.checkPhoneAndSendPin, {phone:phone, country: country});
    // }
    AuthenticationService.prototype.checkPhoneAndSendPin = function (phone, email, authHash) {
        this.loaderService.show();
        var body = {};
        if (phone) {
            body.phone = phone;
        }
        if (email) {
            body.email = email;
        }
        if (authHash) {
            body.authHash = authHash;
        }
        // if (environment.frontEndTest) {
        //     body.country = 'us';
        // }
        return this.http.post(this.config.checkPhoneAndSendPin, body);
    };
    // register(phone: string, pin: string, country: string){
    //     this.loaderService.show();
    //     return this.http.post<any>(this.config.register, {phone:phone, pin:pin, country: country})   .pipe(map((res: any) => {
    //         if (res.status) {
    //             this.setPointAccounts(res.accounts);
    //         }
    //
    //         return res;
    //     }));
    // }
    AuthenticationService.prototype.register = function (firstName, lastName, email, promoCode, password, phone, pin) {
        var _this = this;
        this.loaderService.show();
        return this.http.post(this.config.register, { first_name: firstName, last_name: lastName, email: email, promo_code: promoCode, password: password, phone: phone, pin: pin })
            .pipe(map(function (res) {
            if (res.status) {
                _this.setPointAccounts(res.accounts);
            }
            return res;
        }));
    };
    AuthenticationService.prototype.addPassword = function (phone, firstName, lastName, email, password, pin) {
        var _this = this;
        this.loaderService.show();
        return this.http.post(this.config.addPassword, { phone: phone, firstName: firstName, lastName: lastName, email: email, password: password, pin: pin })
            .pipe(map(function (res) {
            if (res.status) {
                _this.setUserLogged(res.result);
                _this.setPointAccounts(res.accounts);
                localStorage.setItem('authHash', res.authHash);
            }
            return res;
        }));
    };
    // login(phone: string, pin: string) {
    //     this.loaderService.show();
    //
    //     this.storage = sessionStorage;
    //     return this.http.post<any>(this.config.login, {phone, pin})
    //       .pipe(map((res: any) => {
    //           //console.log(res)
    //           if (res.status) {
    //               this.setUserLogged(res.result);
    //               this.setPointAccounts(res.accounts);
    //           }
    //
    //           return res;
    //       }));
    // }
    AuthenticationService.prototype.login = function (email, phone, pin, password, code, authHash) {
        var _this = this;
        this.loaderService.show();
        // this.storage = sessionStorage;
        var body = {
            code: code
        };
        if (email) {
            body.email = email;
        }
        if (phone) {
            body.phone = phone;
        }
        if (password) {
            body.password = password;
        }
        if (pin) {
            body.pin = pin;
        }
        if (authHash) {
            body.authHash = authHash;
        }
        // if (environment.frontEndTest) {
        //     body = {
        //         phone: phone,
        //         pin: pin,
        //         country: 'us'
        //     }
        // }
        return this.http.post(this.config.login, body)
            .pipe(map(function (res) {
            //console.log(res)
            if (res.status) {
                _this.setUserLogged(res.result);
                _this.setPointAccounts(res.accounts);
                localStorage.setItem('authHash', res.authHash);
            }
            return res;
        }));
    };
    AuthenticationService.prototype.forgotPassword = function (email, phone) {
        this.loaderService.show();
        var body = {};
        if (email) {
            body.email = email;
        }
        if (phone) {
            body.phone = phone;
        }
        return this.http.post(this.config.forgotPassword, body);
    };
    AuthenticationService.prototype.restorePassword = function (code, email, password) {
        this.loaderService.show();
        return this.http.post(this.config.restorePassword, { code: code, email: email, password: password });
    };
    AuthenticationService.prototype.playNow = function (token) {
        var _this = this;
        this.loaderService.show();
        return this.http.post(this.configSenetPro.playNow, { token: token })
            .pipe(map(function (res) {
            //console.log(res)
            if (res.status) {
                _this.setUserLogged(res.result);
                _this.setPointAccounts(res.accounts);
            }
            return res;
        }));
    };
    AuthenticationService.prototype.setPointAccounts = function (accounts) {
        this.pointService.savePointAccounts(accounts);
    };
    AuthenticationService.prototype.logoutRequest = function () {
        return this.http.post(this.config.logout, {});
    };
    AuthenticationService.prototype.setUserLogged = function (info) {
        this.setData(info);
        this.userLoggedIn.emit(true);
        this.referralBonusService.setReferralBonusStatus(0);
    };
    AuthenticationService.prototype.setData = function (result) {
        // this.storage = sessionStorage;
        this.storage.setItem('currentUser', JSON.stringify(result));
    };
    AuthenticationService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.storage.getItem('currentUser')) {
                    this.logoutRequest().toPromise();
                }
                this.storage.removeItem('currentUser');
                this.pointService.resetPointsInfo();
                this.userLoggedIn.emit(false);
                this.sessionStorageService.cleanStorage();
                this.referralBonusService.setReferralBonusStatus(0);
                this.posSettingsService.removeStorePageVisibility();
                this.posSettingsService.removePaymentsAvailable();
                document.querySelectorAll('.investments-popup').forEach(function (popup) {
                    popup.classList.remove('is-active');
                });
                this.popupComponent.closePopup();
                return [2 /*return*/];
            });
        });
    };
    AuthenticationService.prototype.refreshTokenNow = function () {
        var _this = this;
        if (this.storage.getItem('currentUser')) {
            this.http.post(this.config.refresh, {}).subscribe(function (res) {
                if (res.status) {
                    _this.setData(res.result);
                }
                else {
                    _this.router.navigate(['/']);
                }
                return res;
            });
        }
        return false;
    };
    AuthenticationService.prototype.refreshToken = function () {
        var $this = this;
        setTimeout(function () {
            $this.refreshToken();
        }, 60 * 5 * 1000);
        this.refreshTokenNow();
    };
    AuthenticationService.prototype.clearStorage = function () {
        this.storage.removeItem('currentUser');
        this.referralBonusService.setReferralBonusStatus(0);
    };
    AuthenticationService.prototype.getCurrentUser = function () {
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser;
            }
            catch (e) {
            }
        }
        return false;
    };
    AuthenticationService.prototype.getToken = function () {
        // this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser.token;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.LoaderService), i0.ɵɵinject(i5.ReferralBonusService), i0.ɵɵinject(i6.PointService), i0.ɵɵinject(i7.PopupComponent), i0.ɵɵinject(i8.PosSettingsService), i0.ɵɵinject(i9.SessionStorageService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
