import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../../service/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserBalanceService} from "../../../service/users/userBalance.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {
  GAME_VENDORS_FIREKIRIN,
  GAME_VENDORS_GOLDENDRAGON, GAME_VENDORS_JUWA, GAME_VENDORS_PUMA,
  GAME_VENDORS_RIVER, GAME_VENDORS_ULTRAPANDA, GAME_VENDORS_VBLINK
} from "../../../config/gameVendors";
import {PopupRewardsGameInfoModel} from "./popup-rewards-game-form.config";
import {RouterPaths} from "../../../config/router-paths.model";
import {LanguageService} from "../../../modules/language/language.service";
import {CurrencyService} from "../../../modules/currency/currency.service";

@Component({
    selector: 'app-popup-rewards-game-form',
    templateUrl: './popup-rewards-game-form.component.html',
    styleUrls: ['./popup-rewards-game-form.component.scss']
})
export class PopupRewardsGameFormComponent implements OnInit {
    @Input() popupInfo: PopupRewardsGameInfoModel;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() updateBalance: EventEmitter<any> = new EventEmitter();
    @Output() onGameSweep: EventEmitter<any> = new EventEmitter();

    balanceForm: FormGroup;
    moreThenAvailablePayout = false;

    ROUTER_PATHS = RouterPaths;
    GAME_VENDORS_RIVER = GAME_VENDORS_RIVER;
    GAME_VENDORS_GOLDENDRAGON = GAME_VENDORS_GOLDENDRAGON;
    GAME_VENDORS_FIREKIRIN = GAME_VENDORS_FIREKIRIN;
    GAME_VENDORS_PUMA = GAME_VENDORS_PUMA;
    GAME_VENDORS_JUWA = GAME_VENDORS_JUWA;
    GAME_VENDORS_VBLINK = GAME_VENDORS_VBLINK;
    GAME_VENDORS_ULTRAPANDA = GAME_VENDORS_ULTRAPANDA;
    constructor(private loaderService: LoaderService,
                private currencyService: CurrencyService,
                private userBalanceService: UserBalanceService,
                private popupComponent: PopupComponent,
                private languageService: LanguageService,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.balanceForm = this.formBuilder.group({
            amount: [null, Validators.required]
        });

    }

    closePopup() {
        this.close.emit(true);
    }

    onInputAmount() {
        const avaliable = typeof this.popupInfo.availableBalance === 'number' ? this.popupInfo.availableBalance : Number.parseFloat(this.popupInfo.availableBalance);
        const entered = Number.parseFloat(this.balanceForm.controls.amount.value);

        if (avaliable < entered) {
            this.balanceForm.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    onSubmitBalanceForm() {
        const amount = this.balanceForm.controls.amount.value;
        if (!amount) {
            const message = this.languageService.getString('messagesData', 'notAllFieldsAreFilled');
            this.popupComponent.showPopupError({text: message});
        } else {
            // const text = `Send $${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}.00  to ${methodName}?`;
            // this.popupComponent.showPopupConfirmCancel({text}, (status) => {
            //     if (status) {
            //
            //     }
            // });
            const providerId = this.popupInfo.gameProviderId;
            this.userBalanceService.increaseBalance(amount, providerId).subscribe(data => {
                this.displaySubmitBalanceResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            })
        }
    }

    displaySubmitBalanceResult(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.balanceForm.controls.amount.setValue(null);
                this.moreThenAvailablePayout = false;
                this.updateBalance.emit();
            }else{
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    sweepGame() {
        this.onGameSweep.emit();
    }

    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount);
    }

    isGameWithAccountAndPassword() {
        return this.popupInfo.gameVendor === this.GAME_VENDORS_FIREKIRIN ||
               this.popupInfo.gameVendor === this.GAME_VENDORS_PUMA ||
               this.popupInfo.gameVendor === this.GAME_VENDORS_JUWA ||
               this.popupInfo.gameVendor === this.GAME_VENDORS_VBLINK ||
               this.popupInfo.gameVendor === this.GAME_VENDORS_ULTRAPANDA;
    }

    showChangingPasswordText() {
        return this.popupInfo.gameVendor === this.GAME_VENDORS_FIREKIRIN ||
               this.popupInfo.gameVendor === this.GAME_VENDORS_GOLDENDRAGON ||
               this.popupInfo.gameVendor === this.GAME_VENDORS_PUMA ||
               this.popupInfo.gameVendor === this.GAME_VENDORS_JUWA ||
               this.popupInfo.gameVendor === this.GAME_VENDORS_VBLINK ||
               this.popupInfo.gameVendor === this.GAME_VENDORS_ULTRAPANDA;
    }

  formatBonuses(amount) {
    return this.currencyService.formatBonuses(amount);
  }
}
