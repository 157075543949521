export const RouterPaths = {
  shop: '/home/shop',
  support: '/home/support',
  checkout: '/home/checkout',
  gamesPlayground: '/games',
  perks: '/home/perks',
  store: '/home/store',
  bonusPoints: '/home/bonus-points',
  account: '/home/account',
  contacts: '/home/contacts',
  commission: '/home/commission',
  termsAndConditions: '/home/terms-and-conditions',
  nftTermsAndConditions: '/home/terms-and-conditions/nft',
  aiTermsAndConditions: '/home/terms-and-conditions/ai'
};
