/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-channels.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../modules/language/language-translate.pipe";
import * as i4 from "../../modules/language/language.service";
import * as i5 from "../popup-prizes-senetpro-confirm/popup-prizes-senetpro-confirm.component.ngfactory";
import * as i6 from "../popup-prizes-senetpro-confirm/popup-prizes-senetpro-confirm.component";
import * as i7 from "../../service/loader.service";
import * as i8 from "../../service/phoneMask.service";
import * as i9 from "../../modules/currency/currency.service";
import * as i10 from "../popup/popup.component";
import * as i11 from "@angular/forms";
import * as i12 from "../popup-debit-card/popup-debit-card.component.ngfactory";
import * as i13 from "../popup-debit-card/popup-debit-card.component";
import * as i14 from "../../modules/country/country.service";
import * as i15 from "../../service/cards.service";
import * as i16 from "@angular/material/core";
import * as i17 from "../popup-withdraw-bank/popup-withdraw-bank.component.ngfactory";
import * as i18 from "../popup-withdraw-bank/popup-withdraw-bank.component";
import * as i19 from "../../service/phoneVerify.service";
import * as i20 from "../../service/partner.service";
import * as i21 from "../popup-withdraw-check/popup-withdraw-check.component.ngfactory";
import * as i22 from "../popup-withdraw-check/popup-withdraw-check.component";
import * as i23 from "../../service/content-displaying.service";
import * as i24 from "./popup-channels.component";
import * as i25 from "../../service/date.service";
import * as i26 from "../../service/channels.service";
var styles_PopupChannelsComponent = [i0.styles];
var RenderType_PopupChannelsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupChannelsComponent, data: {} });
export { RenderType_PopupChannelsComponent as RenderType_PopupChannelsComponent };
function View_PopupChannelsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "methods-list__description methods-list__right"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.description; _ck(_v, 0, 0, currVal_0); }); }
function View_PopupChannelsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "methods-list__description methods-list__right"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.descriptionDisable; _ck(_v, 0, 0, currVal_0); }); }
function View_PopupChannelsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "methods-list__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "methods-list__btn methods-list__left"]], [[2, "is-limited", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onChannelClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupChannelsComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupChannelsComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (!_v.context.$implicit.disable || !_co.isDisableTimeActual(_v.context.$implicit.disableTime)); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_v.context.$implicit.disable && _co.isDisableTimeActual(_v.context.$implicit.disableTime)); _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.limits; var currVal_1 = (_v.context.$implicit.disable && _co.isDisableTimeActual(_v.context.$implicit.disableTime)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_2); }); }
function View_PopupChannelsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "methods-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "js-popup-subtitle investments-popup__header--centered"], ["style", "margin-bottom: 1rem;font-size: 0.8rem;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "methods-list__item methods-list__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "methods-list__left"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(0, i3.LanguageTranslatePipe, [i4.LanguageService]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "methods-list__right"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(0, i3.LanguageTranslatePipe, [i4.LanguageService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupChannelsComponent_3)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.availableMethods; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.minimumWithdrawalMessage; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("channelTitle")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("channelCommentsTitle")); _ck(_v, 8, 0, currVal_2); }); }
function View_PopupChannelsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "investments-popup__inner  js-prizes-parent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "button", [["class", "investments-popup__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "investments-popup__scrolled"], ["id", "scrolled-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupChannelsComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(8, { visibility: 0, maxHeight: 1 }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-popup-prizes-senetpro-confirm", [], null, [[null, "changeHandle"], [null, "confirm"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changeHandle" === en)) {
        var pd_0 = (_co.changeHandlePopupConfirm($event) !== false);
        ad = (pd_0 && ad);
    } if (("confirm" === en)) {
        var pd_1 = (_co.submitPopupConfirm($event) !== false);
        ad = (pd_1 && ad);
    } if (("close" === en)) {
        var pd_2 = (_co.closePopupConfirm() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_PopupPrizesSenetproConfirmComponent_0, i5.RenderType_PopupPrizesSenetproConfirmComponent)), i1.ɵdid(10, 638976, null, 0, i6.PopupPrizesSenetproConfirmComponent, [i7.LoaderService, i8.PhoneMaskService, i9.CurrencyService, i4.LanguageService, i10.PopupComponent, i11.FormBuilder], { popupInfo: [0, "popupInfo"], isPopup: [1, "isPopup"] }, { close: "close", changeHandle: "changeHandle", confirm: "confirm" }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-popup-debit-card", [], null, [[null, "back"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.closeDebitCardPopupBack($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.closeDebitCardPopup() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_PopupDebitCardComponent_0, i12.RenderType_PopupDebitCardComponent)), i1.ɵdid(12, 638976, null, 0, i13.PopupDebitCardComponent, [i7.LoaderService, i4.LanguageService, i14.CountryService, i15.CardsService, i16.DateAdapter, i10.PopupComponent, i11.FormBuilder], { popupInfo: [0, "popupInfo"], isPopup: [1, "isPopup"] }, { close: "close", back: "back" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.popupConfirmInfo.show && !_co.popupDebitCardInfo.show); _ck(_v, 4, 0, currVal_0); var currVal_1 = _ck(_v, 8, 0, (_co.isCardEditing ? "hidden" : "visible"), (_co.isCardEditing ? 0 : "initial")); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.popupConfirmInfo; var currVal_3 = false; _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_4 = _co.popupDebitCardInfo; var currVal_5 = false; _ck(_v, 12, 0, currVal_4, currVal_5); }, null); }
function View_PopupChannelsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "text-center form-btn-submit page__form-btns"], ["style", "margin-bottom: 1rem; width: calc(50% - .5rem); max-width: 10rem; margin-left: .5rem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "button"], ["style", "min-width: unset; width: 100%"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCallMe() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(0, i3.LanguageTranslatePipe, [i4.LanguageService]), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("btnCallMe")); _ck(_v, 2, 0, currVal_0); }); }
function View_PopupChannelsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-withdraw-bank", [], null, [[null, "onSuccess"], [null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSuccess" === en)) {
        var pd_0 = (_co.closePopupConfirm(true) !== false);
        ad = (pd_0 && ad);
    } if (("onClose" === en)) {
        var pd_1 = (_co.closeBankPopup() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i17.View_PopupWithdrawBankComponent_0, i17.RenderType_PopupWithdrawBankComponent)), i1.ɵdid(1, 770048, null, 0, i18.PopupWithdrawBankComponent, [i11.FormBuilder, i7.LoaderService, i4.LanguageService, i19.PhoneVerifyService, i20.PartnerService, i10.PopupComponent, i9.CurrencyService, i15.CardsService], { popupInfo: [0, "popupInfo"] }, { emitClose: "onClose", onSuccess: "onSuccess" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupBankInfo; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopupChannelsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "investments-popup investments-popup--pay"]], [[2, "is-active", null], [2, "is-blurred", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupChannelsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 17, "div", [["class", "investments-popup"], ["style", "z-index: 9999"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 16, "div", [["class", "investments-popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "button", [["class", "investments-popup__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeCashPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 14, "div", [["class", "investments-popup__scrolled"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "js-popup-subtitle investments-popup__header--centered"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 11, "div", [["class", "form-simple__btns"], ["style", "display: flex; flex-direction: row; justify-content: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "text-center form-btn-submit page__form-btns"], ["style", "width: calc(50% - .5rem); margin-left: auto; max-width: 10rem"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(13, { marginRight: 0, marginBottom: 1, marginLeft: 2 }), (_l()(), i1.ɵeld(14, 0, null, null, 4, "button", [["class", "button"], ["style", "min-width: unset; width: 100%"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeCashPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(15, null, [" ", " "])), i1.ɵpid(0, i3.LanguageTranslatePipe, [i4.LanguageService]), (_l()(), i1.ɵeld(17, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupChannelsComponent_6)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 1, "app-popup-withdraw-check", [], null, [[null, "onSuccess"], [null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSuccess" === en)) {
        var pd_0 = (_co.closePopupConfirm(true) !== false);
        ad = (pd_0 && ad);
    } if (("onClose" === en)) {
        var pd_1 = (_co.closeCheckPopup() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i21.View_PopupWithdrawCheckComponent_0, i21.RenderType_PopupWithdrawCheckComponent)), i1.ɵdid(22, 770048, null, 0, i22.PopupWithdrawCheckComponent, [i11.FormBuilder, i7.LoaderService, i4.LanguageService, i19.PhoneVerifyService, i20.PartnerService, i10.PopupComponent, i23.ContentDisplayingService, i9.CurrencyService, i15.CardsService], { popupInfo: [0, "popupInfo"] }, { emitClose: "onClose", onSuccess: "onSuccess" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupChannelsComponent_7)), i1.ɵdid(24, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.popupInfo.show; _ck(_v, 2, 0, currVal_2); var currVal_5 = _ck(_v, 13, 0, (_co.cashTickets ? ".5rem" : "auto"), (_co.cashTickets ? "1rem" : 0), (_co.cashTickets ? "0" : "auto")); _ck(_v, 12, 0, currVal_5); var currVal_7 = _co.cashTickets; _ck(_v, 20, 0, currVal_7); var currVal_8 = _co.popupCheckInfo; _ck(_v, 22, 0, currVal_8); var currVal_9 = _co.popupBankInfo.show; _ck(_v, 24, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupInfo.show; var currVal_1 = ((_co.isCashPopupShown || _co.popupCheckInfo.show) || _co.popupBankInfo.show); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.isCashPopupShown; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.cashMessage; _ck(_v, 8, 0, currVal_4); var currVal_6 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("btnBack")); _ck(_v, 15, 0, currVal_6); }); }
export function View_PopupChannelsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-channels", [], null, null, null, View_PopupChannelsComponent_0, RenderType_PopupChannelsComponent)), i1.ɵdid(1, 638976, null, 0, i24.PopupChannelsComponent, [i7.LoaderService, i10.PopupComponent, i4.LanguageService, i19.PhoneVerifyService, i25.DateService, i9.CurrencyService, i26.ChannelsService, i15.CardsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupChannelsComponentNgFactory = i1.ɵccf("app-popup-channels", i24.PopupChannelsComponent, View_PopupChannelsComponent_Host_0, { popupInfo: "popupInfo" }, { close: "close" }, []);
export { PopupChannelsComponentNgFactory as PopupChannelsComponentNgFactory };
