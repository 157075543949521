export interface SenetproTransactionsModel {
  amount: number;
  balance: number;
  created: string;
  method_name: string;
  type: string;
}

export const TRANSACTIONS_LIST_TYPE = {
  history: "history",
  transactions: "transactions",
  bonuses: "bonuses",
}
