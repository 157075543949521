import {Injectable} from '@angular/core';
import * as defaultText from '../../../assets/languages/en/text.json'
import {BehaviorSubject} from "rxjs";
import {formatCurrency} from "@angular/common";

const CURRENCIES = {
  USD: {
    locale: 'en',
    currency: '$'
  },
  MXN: {
    locale: 'es-MX',
    currency: '$'
  }
}

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  activeCurrencyKey = 'activeCurrency';
  defaultCurrency = 'USD';
  activeCurrency;

  // requestNewLanguage = new BehaviorSubject(null);
  currencyIsUpdated = new BehaviorSubject(null);

  constructor() {}

  setCurrency(currency) {
    const newCurrency = currency ? currency : this.defaultCurrency;
    this.activeCurrency = newCurrency.toUpperCase();
    localStorage.setItem(this.activeCurrencyKey, this.activeCurrency);
    this.currencyIsUpdated.next(Math.random());
  }

  getActiveCurrency() {
    return localStorage.getItem(this.activeCurrencyKey);
  }

  formatCurrency(amount) {
    const definedCurrency = CURRENCIES[this.getActiveCurrency()];
    if (definedCurrency) {
      return formatCurrency(+amount, definedCurrency.locale, definedCurrency.currency);
    } else {
      return formatCurrency(+amount, 'en', '') + ' ' + this.getActiveCurrency();
    }
  }

  formatBonuses(amount) {
    return Number(amount).toFixed(2);
  }
}
