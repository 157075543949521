/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-senetpro-transactions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../senetpro-transactions/senetpro-transactions.component.ngfactory";
import * as i3 from "../senetpro-transactions/senetpro-transactions.component";
import * as i4 from "../../modules/currency/currency.service";
import * as i5 from "@angular/common";
import * as i6 from "./popup-senetpro-transactions.component";
import * as i7 from "../../service/loader.service";
import * as i8 from "../../service/perks.service";
import * as i9 from "../../service/account.service";
import * as i10 from "../../service/bonus-point.service";
import * as i11 from "../../service/pageScroll/paginationScroll.service";
var styles_PopupSenetproTransactionsComponent = [i0.styles];
var RenderType_PopupSenetproTransactionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupSenetproTransactionsComponent, data: {} });
export { RenderType_PopupSenetproTransactionsComponent as RenderType_PopupSenetproTransactionsComponent };
function View_PopupSenetproTransactionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "investments-popup__inner  js-prizes-parent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "button", [["class", "investments-popup__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "dropdown"], ["style", "padding-bottom: 0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [["scrolledEl", 1]], null, 2, "div", [["class", "dropdown__body dropdown__body--prizes"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.scroll($event, _co.prizesData) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-senetpro-transactions", [], null, null, null, i2.View_SenetproTransactionsComponent_0, i2.RenderType_SenetproTransactionsComponent)), i1.ɵdid(5, 114688, null, 0, i3.SenetproTransactionsComponent, [i4.CurrencyService], { items: [0, "items"], type: [1, "type"], isStickyHeader: [2, "isStickyHeader"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.prizesData.items; var currVal_1 = _co.type; var currVal_2 = false; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PopupSenetproTransactionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "investments-popup investments-popup--pay"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupSenetproTransactionsComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.popupInfo.show; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupInfo.show; _ck(_v, 0, 0, currVal_0); }); }
export function View_PopupSenetproTransactionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-senetpro-transactions", [], null, null, null, View_PopupSenetproTransactionsComponent_0, RenderType_PopupSenetproTransactionsComponent)), i1.ɵdid(1, 638976, null, 0, i6.PopupSenetproTransactionsComponent, [i7.LoaderService, i8.PerksService, i9.AccountService, i10.BonusPointService, i11.PaginationScrollService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupSenetproTransactionsComponentNgFactory = i1.ɵccf("app-popup-senetpro-transactions", i6.PopupSenetproTransactionsComponent, View_PopupSenetproTransactionsComponent_Host_0, { popupInfo: "popupInfo", type: "type" }, { close: "close" }, []);
export { PopupSenetproTransactionsComponentNgFactory as PopupSenetproTransactionsComponentNgFactory };
