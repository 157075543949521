import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {LoaderService} from "./loader.service";
import {BONUS_POINTS_CONFIG} from "../config/api";


@Injectable({
    providedIn: 'root'
})
export class BonusPointService {
    private config = BONUS_POINTS_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
    }

    getPageInfo(page) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPageInfo, {page});
    }
}
