import { BONUS_POINTS_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var BonusPointService = /** @class */ (function () {
    function BonusPointService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = BONUS_POINTS_CONFIG;
    }
    BonusPointService.prototype.getPageInfo = function (page) {
        this.loaderService.show();
        return this.http.post(this.config.getPageInfo, { page: page });
    };
    BonusPointService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BonusPointService_Factory() { return new BonusPointService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: BonusPointService, providedIn: "root" });
    return BonusPointService;
}());
export { BonusPointService };
