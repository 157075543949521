import {DOMAIN_API} from "./local_settings";
import {
    DOMAIN_DEMO_NAME, DOMAIN_DEMO_API, DOMAIN_DEMO_NAME1
} from "./demo_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {environment} from "../../environments/environment";
import {ShopRequestsModel} from "../models/shop/shop_requests.model";
import {UserContact} from "../models/user-contact";
import {SupportModel} from "../models/support/support.model";
import {UserBalance} from "../models/user-balance";
import {GamesProvider} from "../models/gamesProvider";
import {CheckoutRequestsModel} from "../models/checkout/checkout_requests.model";
import {PerksRequestsModel} from "../models/perks/perks_requests.model";
import {AccountRequestsModel} from "../models/account/account_requests.model";
import {WalletRequestsModel} from "../models/wallet/wallet_requests.model";
import {AppVersion} from '../models/appVersion';
import {ContactUsRequestsModel} from "../models/contact-us/contact-us_requests.model";
import {PartnersRequestsModel} from "../models/partners/partners_requests.model";
import {SpinnerGameModel} from "../models/spiner/spinner-game.model";

let domainApiUrl = DOMAIN_API;
if(window.location.hostname == DOMAIN_DEMO_NAME || window.location.hostname == DOMAIN_DEMO_NAME1){
    domainApiUrl = DOMAIN_DEMO_API;
}

let API = domainApiUrl;
let API_SENETPRO = API + '/senetpro';
const USERS = API_SENETPRO + '/users';
const WALLETS = API_SENETPRO + '/wallets';

const SHOP = API_SENETPRO + '/shop';
const STORE = API_SENETPRO + '/store';
const GAMES = API_SENETPRO + '/games';
const CHECKOUT = API_SENETPRO + '/checkout';
const REDEEM = API_SENETPRO + '/redeem';
const BONUSES = API_SENETPRO + '/bonuses';
const ACCOUNT = API_SENETPRO + '/account';
const AI = API_SENETPRO + '/ai';

if (environment.frontEndTest) {
    API_SENETPRO = DOMAIN_API + '/client';
}

export const AUTH_CONFIG: AuthConfig = {
    checkPhoneAndSendPin: USERS + '/user/login',
    register: USERS + '/user/register',
    login: USERS + '/user/auth',
    logout: USERS + '/session/logout',
    forgotPassword: USERS + '/password/forgot',
    restorePassword: USERS + '/password/restore',
    addPassword: USERS + '/password/add',
    refresh: USERS + '/session/refresh',
};
if (environment.frontEndTest) {
    AUTH_CONFIG.checkPhoneAndSendPin = API_SENETPRO + '/jsons/find/file?name=loginAddPasswordError';
    AUTH_CONFIG.login = API_SENETPRO + '/users/session/login';
    AUTH_CONFIG.refresh = API_SENETPRO + '/users/session/refresh';
    AUTH_CONFIG.logout = API_SENETPRO + '/users/session/logout';
    AUTH_CONFIG.register =  API_SENETPRO + '/users/register';
    AUTH_CONFIG.addPassword =  API_SENETPRO + '/users/addPassword';
    AUTH_CONFIG.forgotPassword =  API_SENETPRO + '/jsons/find/file?name=success';
    AUTH_CONFIG.restorePassword =  API_SENETPRO + '/jsons/find/file?name=success';
}

export const USER_CONTACT_CONFIG: UserContact = {
    create: USERS + '/contact/create',
    save: USERS + '/contact/save'
};
if (environment.frontEndTest) {
    USER_CONTACT_CONFIG.create =  API_SENETPRO + '/jsons/find/file?name=success';
    USER_CONTACT_CONFIG.save =  API_SENETPRO + '/jsons/find/file?name=error';
}

export const SHOP_CONFIG: ShopRequestsModel = {
    getPageInfo: SHOP + '/home',
    onActivate: SHOP + '/card/activate',
    showSellCryptoPopup: SHOP + '/bitcoin',
    sellCryptoEstimate: SHOP + '/bitcoin/estimate',
    onSellCryptoRequest: SHOP + '/bitcoin/sell',
    onSellGiftCards: SHOP + '/gifts/sell',
};
if (environment.frontEndTest) {
    SHOP_CONFIG.getPageInfo = API_SENETPRO + '/jsons/find/file?name=senetShopPageInfo';
    SHOP_CONFIG.onActivate = API_SENETPRO + '/jsons/find/file?name=success';
    SHOP_CONFIG.showSellCryptoPopup = API_SENETPRO + '/jsons/find/file?name=senetShopSellCryptoPopup';
    SHOP_CONFIG.sellCryptoEstimate = API_SENETPRO + '/jsons/find/file?name=sellCryptoEstimate';
    SHOP_CONFIG.onSellCryptoRequest = API_SENETPRO + '/jsons/find/file?name=success';
    SHOP_CONFIG.onSellGiftCards = API_SENETPRO + '/jsons/find/file?name=success';
}

export const WALLET_CONFIG: WalletRequestsModel = {
    generate: WALLETS + '/wallet/generate',
    crypto: WALLETS + '/wallet/crypto',
};
if (environment.frontEndTest) {
    WALLET_CONFIG.generate = API_SENETPRO + '/jsons/find/file?name=successTest';
    WALLET_CONFIG.crypto = API_SENETPRO + '/jsons/find/file?name=success';
}

export const CHECKOUT_CONFIG: CheckoutRequestsModel = {
    getPageInfo: CHECKOUT + '/page',
    getGenerateInfo: CHECKOUT + '/ai/images',
    getMintInfo: CHECKOUT + '/ai/nfts',

    buyWithCard: CHECKOUT + '/ai/card',
    buyWithCashApp: CHECKOUT + '/ai/cashapp',

    generateOne: AI + '/image/manual',
    generateAll: AI + '/image/instant',
    generateText: CHECKOUT + '/ai/text',
    onMintImages: AI + '/nft/mint',
};
if (environment.frontEndTest) {
    CHECKOUT_CONFIG.getPageInfo = API_SENETPRO + '/jsons/find/file?name=senetCheckoutPageInfo';

    // CHECKOUT_CONFIG.buyWithCard = API_SENETPRO + '/jsons/find/file?name=threeDSPayment';
    CHECKOUT_CONFIG.buyWithCard = API_SENETPRO + '/jsons/find/file?name=success';

    CHECKOUT_CONFIG.buyWithCashApp = API_SENETPRO + '/jsons/find/file?name=success';
    CHECKOUT_CONFIG.getGenerateInfo = API_SENETPRO + '/jsons/find/file?name=senetCheckoutGenerateInfo';
    CHECKOUT_CONFIG.generateOne = API_SENETPRO + '/jsons/find/file?name=senetCheckoutGenerateOne';
    CHECKOUT_CONFIG.generateAll = API_SENETPRO + '/jsons/find/file?name=success';
    CHECKOUT_CONFIG.getMintInfo = API_SENETPRO + '/jsons/find/file?name=senetCheckoutMintInfo';
    CHECKOUT_CONFIG.onMintImages = API_SENETPRO + '/jsons/find/file?name=successMint';
    CHECKOUT_CONFIG.generateText = API_SENETPRO + '/jsons/find/file?name=generateText';
}

export const PERKS_CONFIG: PerksRequestsModel = {
    getPageInfo: REDEEM + '/page',
    getGameBalance: REDEEM + '/page/game',
    redeem: REDEEM + '/game',
};
if (environment.frontEndTest) {
    PERKS_CONFIG.getPageInfo = API_SENETPRO + '/jsons/find/file?name=senetPerksPageInfo';
    PERKS_CONFIG.getGameBalance = API_SENETPRO + '/jsons/find/file?name=getGameBalance';
    PERKS_CONFIG.redeem = API_SENETPRO + '/jsons/find/file?name=successTest';
}

export const BONUS_POINTS_CONFIG = {
  getPageInfo: BONUSES + '/bonus',
};
if (environment.frontEndTest) {
  BONUS_POINTS_CONFIG.getPageInfo = API_SENETPRO + '/jsons/find/file?name=senetBonusPointsPageInfo';
}

export const ACCOUNT_CONFIG: AccountRequestsModel = {
    getPageInfo: ACCOUNT + '/page',
    onRevealPrivateKey: ACCOUNT + '/bitcoin/key',
    onReceiveCrypto: ACCOUNT + '/bitcoin/receive',
    onSendCrypto: ACCOUNT + '/bitcoin/send',
    onSaveContacts: ACCOUNT + '/contact/save',
    onChangeEmail: USERS + '/change/email',
    onChangePhone: USERS + '/change/phone',
    onChangePassword: USERS + '/change/password',
    onChangePin: USERS + '/change/pin',
    requestVerifyPhone: USERS + '/phone/pin',
    onVerifyPhone: USERS + '/phone/verify',
    requestVerifyEmail: USERS + '/email/code',
    onVerifyEmail: USERS + '/email/verify',
    onLibraryImageStatusChange: ACCOUNT + '/image/privacy'
};
if (environment.frontEndTest) {
    ACCOUNT_CONFIG.getPageInfo = API_SENETPRO + '/jsons/find/file?name=senetAccountPageInfo';
    ACCOUNT_CONFIG.onRevealPrivateKey = API_SENETPRO + '/jsons/find/file?name=onRevealPrivateKey';
    ACCOUNT_CONFIG.onReceiveCrypto = API_SENETPRO + '/jsons/find/file?name=onRevealPrivateKey';
    ACCOUNT_CONFIG.onSendCrypto = API_SENETPRO + '/jsons/find/file?name=success';
    ACCOUNT_CONFIG.onSaveContacts = API_SENETPRO + '/jsons/find/file?name=success';

    ACCOUNT_CONFIG.onChangeEmail = API_SENETPRO + '/jsons/find/file?name=success';
    ACCOUNT_CONFIG.onChangePhone = API_SENETPRO + '/jsons/find/file?name=success';
    ACCOUNT_CONFIG.onChangePassword = API_SENETPRO + '/jsons/find/file?name=success';
    ACCOUNT_CONFIG.onChangePin = API_SENETPRO + '/jsons/find/file?name=success';

    ACCOUNT_CONFIG.requestVerifyPhone = API_SENETPRO + '/jsons/find/file?name=success';
    ACCOUNT_CONFIG.onVerifyPhone = API_SENETPRO + '/jsons/find/file?name=success';
    ACCOUNT_CONFIG.requestVerifyEmail = API_SENETPRO + '/jsons/find/file?name=success';
    ACCOUNT_CONFIG.onVerifyEmail = API_SENETPRO + '/jsons/find/file?name=success';
    ACCOUNT_CONFIG.onLibraryImageStatusChange = API_SENETPRO + '/jsons/find/file?name=success';
}

export const CHANNELS_CONFIG = {
    getChannelsList: API_SENETPRO + '/redeem/methods/sell',
    onCallMe: API_SENETPRO + '/redeem/cash/ticket',
};
if (environment.frontEndTest) {
    CHANNELS_CONFIG.getChannelsList = API_SENETPRO + '/jsons/find/file?name=senetPerksChannelsAvailable';
    CHANNELS_CONFIG.onCallMe = API_SENETPRO + '/jsons/find/file?name=error';
}

export const SUPPORT_CONFIG: SupportModel = {
    session: API_SENETPRO + '/chat/session/create', // need To change
};
if (environment.frontEndTest) {
  SUPPORT_CONFIG.session = API_SENETPRO + '/jsons/find/file?name=createSupportSession';
}

export const USER_BALANCE_CONFIG: UserBalance = {
    getBalance: USERS + '/balance',
    increaseBalance: API_SENETPRO + '/games/game/deposit', // need To change (shop page game play popup)
}
if (environment.frontEndTest) {
    USER_BALANCE_CONFIG.getBalance = API_SENETPRO + '/jsons/find/file?name=senetGetMainBalance';
    USER_BALANCE_CONFIG.increaseBalance = API_SENETPRO + '/jsons/find/file?name=success';
}

export const GAMES_PROVIDER_CONFIG: GamesProvider = {
    getSenetSession: API + '/games/senet/session/create',
}
if (environment.frontEndTest) {
    GAMES_PROVIDER_CONFIG.getSenetSession = API_SENETPRO + '/jsons/find/file?name=getSenetSession';
}

export const PRIZES_CONFIG = {
    removeDebitCard: CHECKOUT + '/card/delete',
    addDebitCard: CHECKOUT + '/card/add',

    addAdvancedDebitCard: REDEEM + '/debitcard/create',
    addBankAccount: REDEEM + '/bank/create',
    addCashAppAccount: REDEEM + '/cashapp/create',

    getDebitCards: REDEEM + '/debitcard',
    getBankAccounts: REDEEM + '/bank',
    getCashAppAccounts: REDEEM + '/cashapp',

    changeActiveCard: REDEEM + '/debitcard/savedefault',
    changeActiveBankAccount: REDEEM + '/bank/savedefault',
    changeActiveCashAppAccount: REDEEM + '/cashapp/savedefault',

    removeAdvancedDebitCard: REDEEM + '/debitcard/delete',
    removeBankAccount: REDEEM + '/bank/delete',
    removeCashAppAccount: REDEEM + '/cashapp/delete',

    redeemViaDebitCard: REDEEM + '/debitcard/payout',
    redeemViaBankAccount: REDEEM + '/bank/payout',
    redeemViaCashApp: REDEEM + '/cashapp/payout',

    redeemViaVisaGiftCard: REDEEM + '/giftcard/visa',
    redeemViaAmazonGiftCard: REDEEM + '/giftcard/amazon',
    redeemViaVenmoTag: REDEEM + '/venmo/create',
    redeemViaVenmoPaypal: REDEEM + '/paypal/create',

    getGiftCardInfo: REDEEM + '/giftcard',

    getDebitCardInfo: REDEEM + '/debitcard/info',
    getDebitCardAdditionInfo: REDEEM + '/debitcard',
    addDebitCardAdditionInfo: REDEEM + '/legalinfo/save',
    getBankAccountInfo: REDEEM + '/bank/info', // need to change
    getCashAppInfo: REDEEM + '/cashapp/info', // need to change

    withdraw: REDEEM + '/balance/game',

    getPaypalInfo: API_SENETPRO + '/redeem/paypal',
    getVenmoInfo: API_SENETPRO + '/redeem/venmo',
    verifyPaypal: API_SENETPRO + '/redeem/paypal/add',
    verifyVenmo: API_SENETPRO + '/redeem/venmo/add',
    verifyCard: CHECKOUT + '/card/verify',

    getPaynoteInfo: API_SENETPRO + '/redeem/paynote',
    redeemViaPaynote: REDEEM + '/paynote/create',

    getSavedInstitutions: REDEEM + '/paynote/info',
    sendLegalData: REDEEM + '/legalinfo/name',
    getRoutingList: REDEEM + '/institutions/routing',
    redeemViaPaynoteDirect: REDEEM + '/paynote/direct',
};
if (environment.frontEndTest) {
    PRIZES_CONFIG.removeDebitCard = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.addDebitCard = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.addBankAccount = API_SENETPRO + '/jsons/find/file?name=addDebitCard';
    PRIZES_CONFIG.addCashAppAccount = API_SENETPRO + '/jsons/find/file?name=addDebitCard';
    PRIZES_CONFIG.addAdvancedDebitCard = API_SENETPRO + '/jsons/find/file?name=addDebitCard';
    PRIZES_CONFIG.getDebitCards = API_SENETPRO + '/jsons/find/file?name=getDebitCards';
    PRIZES_CONFIG.changeActiveCard = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.changeActiveBankAccount = API_SENETPRO + '/jsons/find/file?name=successTest';
    PRIZES_CONFIG.removeAdvancedDebitCard = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.removeBankAccount = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.getGiftCardInfo = API_SENETPRO + '/jsons/find/file?name=getGiftCardInfo';
    PRIZES_CONFIG.redeemViaVisaGiftCard = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.redeemViaAmazonGiftCard = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.redeemViaVenmoTag = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.redeemViaDebitCard = API_SENETPRO + '/jsons/find/file?name=successCurrent';
    PRIZES_CONFIG.redeemViaBankAccount = API_SENETPRO + '/jsons/find/file?name=successTest';
    PRIZES_CONFIG.getDebitCards = API_SENETPRO + '/jsons/find/file?name=getDebitCards';
    PRIZES_CONFIG.getBankAccounts = API_SENETPRO + '/jsons/find/file?name=getBankAccounts';
    PRIZES_CONFIG.changeActiveCard = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.addDebitCard = API_SENETPRO + '/jsons/find/file?name=addDebitCard';
    PRIZES_CONFIG.addDebitCardAdditionInfo = API_SENETPRO + '/jsons/find/file?name=addDebitCard';
    PRIZES_CONFIG.getDebitCardAdditionInfo = API_SENETPRO + '/jsons/find/file?name=getDebitCardAdditionInfo';
    PRIZES_CONFIG.getPaypalInfo = API_SENETPRO + '/jsons/find/file?name=getPaypalInfo';
    PRIZES_CONFIG.getVenmoInfo = API_SENETPRO + '/jsons/find/file?name=getVenmoInfo';
    PRIZES_CONFIG.redeemViaVenmoPaypal = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.getDebitCardInfo = API_SENETPRO + '/jsons/find/file?name=getDebitCardInfo';
    PRIZES_CONFIG.withdraw = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.getBankAccountInfo = API_SENETPRO + '/jsons/find/file?name=getBankAccountInfo';
    PRIZES_CONFIG.getCashAppInfo = API_SENETPRO + '/jsons/find/file?name=getCashAppInfo';
    PRIZES_CONFIG.redeemViaCashApp = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.getCashAppAccounts = API_SENETPRO + '/jsons/find/file?name=getCashAppAccounts';
    PRIZES_CONFIG.changeActiveCashAppAccount = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.removeCashAppAccount = API_SENETPRO + '/jsons/find/file?name=error';
    PRIZES_CONFIG.verifyCard = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.verifyPaypal = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.verifyVenmo = API_SENETPRO + '/jsons/find/file?name=success';
    PRIZES_CONFIG.getPaynoteInfo = API_SENETPRO + '/jsons/find/file?name=getPaynoteInfo';
    PRIZES_CONFIG.redeemViaPaynote = API_SENETPRO + '/jsons/find/file?name=successTest';
    PRIZES_CONFIG.getRoutingList = API_SENETPRO + '/jsons/find/file?name=routingList';
    PRIZES_CONFIG.redeemViaPaynoteDirect = API_SENETPRO + '/jsons/find/file?name=successTest';
    PRIZES_CONFIG.getSavedInstitutions = API_SENETPRO + '/jsons/find/file?name=senetPerksGetBankInfo';
    PRIZES_CONFIG.sendLegalData = API_SENETPRO + '/jsons/find/file?name=successTest';
}

export const WITHDRAW_CHECK_CONFIG = {
    getAddress: REDEEM + '/check/address',
    getTotal: REDEEM + '/check/cart',
    onConfirm: REDEEM + '/check/create',
};
if (environment.frontEndTest) {
    WITHDRAW_CHECK_CONFIG.getAddress = API_SENETPRO + '/jsons/find/file?name=senetPerksChannelsCheckGetAddress';
    WITHDRAW_CHECK_CONFIG.getTotal = API_SENETPRO + '/jsons/find/file?name=senetPerksChannelsCheckGetTotal';
    WITHDRAW_CHECK_CONFIG.onConfirm = API_SENETPRO + '/jsons/find/file?name=senetPerksChannelsCheckGetPay';
}

export const TRANSACTIONS_CONFIG = {
    confirmation: API_SENETPRO + '/checkout/threedsecure',
};

export const COMMISSION_CONFIG = {
    // data: API + '/resellers/reseller',
    // refer: API + '/resellers/reseller/refer',
    // payout: API + '/resellers/reseller/payout',

    data: API_SENETPRO + '/rewards/commission',
    refer: API_SENETPRO + '/rewards/commission/refer',
    payout: API_SENETPRO + '/rewards/commission/payout',
};
if (environment.frontEndTest) {
  COMMISSION_CONFIG.data = API_SENETPRO + '/jsons/find/file?name=commissionData';
  COMMISSION_CONFIG.payout = API_SENETPRO + '/jsons/find/file?name=success'
}

export const TERMS_AND_CONDITIONS_CONFIG = {
    getPageInfo: API_SENETPRO +'/app/terms',
};
if (environment.frontEndTest) {
    TERMS_AND_CONDITIONS_CONFIG.getPageInfo = API_SENETPRO + '/jsons/find/file?name=termsAndConditions'
}

export const APP_VERSION_CONFIG: AppVersion = {
    version: API_SENETPRO +'/app/version',
};

export const SENETPRO_CONFIG = {
    playNow: API + '/ads/senetpro/register/auth',
}
if (environment.frontEndTest) {
    SENETPRO_CONFIG.playNow = API_SENETPRO + '/jsons/find/file?name=playNow'
}


export const CONTACT_US_CONFIG: ContactUsRequestsModel = {
    send: API_SENETPRO + '/contacts/contact/send',
};
if (environment.frontEndTest) {
    CONTACT_US_CONFIG.send = API_SENETPRO + '/jsons/find/file?name=successTest';
}

export const PARTNERS_CONFIG: PartnersRequestsModel = {
    partner: API_SENETPRO+'/partners/partner',
};
if (environment.frontEndTest) {
    PARTNERS_CONFIG.partner = API_SENETPRO + '/jsons/find/file?name=partner';
}

export const SPINNER_GAME_CONFIG: SpinnerGameModel = {
    init:  API_SENETPRO + '/games/wheel/play',
};
if (environment.frontEndTest) {
    SPINNER_GAME_CONFIG.init = API_SENETPRO + '/jsons/find/file?name=initSpinner'
}

export const STATES_CONFIG = {
    getStates:  API_SENETPRO + '/states/states',
};
if (environment.frontEndTest) {
    STATES_CONFIG.getStates = API_SENETPRO + '/jsons/find/file?name=statesList'
}

export const STORE_CONFIG = {
    getPageInfo: STORE + '/products',
    getAuthorsList: STORE + '/search/name',
    onSearch: STORE + '/search',
    getCartInfo: STORE + '/cart',
    addToCart: STORE + '/cart/add',
    editItem: STORE + '/cart/update',
    changeQuantity: STORE + '/cart/quantity',
    deleteProduct: STORE + '/cart/delete',
    shippingRate: STORE + '/shipping/rate',
    buyWithCard: STORE + '/order/card',
    buyWithCashApp: STORE + '/order/cashapp',
    buyWithBonuses: STORE + '/order/create'
};
if (environment.frontEndTest) {
    STORE_CONFIG.getPageInfo = API_SENETPRO + '/jsons/find/file?name=senetStoreInfo';
    STORE_CONFIG.getAuthorsList = API_SENETPRO + '/jsons/find/file?name=senetStoreAuthorsList';
    STORE_CONFIG.onSearch = API_SENETPRO + '/jsons/find/file?name=senetStoreSearch';
    STORE_CONFIG.getCartInfo = API_SENETPRO + '/jsons/find/file?name=senetStoreGetCartInfo';
    STORE_CONFIG.addToCart = API_SENETPRO + '/jsons/find/file?name=success';
    STORE_CONFIG.changeQuantity = API_SENETPRO + '/jsons/find/file?name=success';
    STORE_CONFIG.deleteProduct = API_SENETPRO + '/jsons/find/file?name=success';
    STORE_CONFIG.shippingRate = API_SENETPRO + '/jsons/find/file?name=senetStoreShippingRate';
    STORE_CONFIG.buyWithCard = API_SENETPRO + '/jsons/find/file?name=error';
    STORE_CONFIG.buyWithCashApp = API_SENETPRO + '/jsons/find/file?name=error';
    STORE_CONFIG.buyWithBonuses = API_SENETPRO + '/jsons/find/file?name=error';
    STORE_CONFIG.editItem = API_SENETPRO + '/jsons/find/file?name=success';
}

export const GAMES_CONFIG = {
    getPageInfo: GAMES + '/games'
};
if (environment.frontEndTest) {
    GAMES_CONFIG.getPageInfo = API_SENETPRO + '/jsons/find/file?name=senetGamesPageInfo';
}
