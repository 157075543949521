import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../popup/popup.component";
import {CardsService} from "../../service/cards.service";
import {LanguageService} from "../../modules/language/language.service";
import {PhoneVerifyService} from "../../service/phoneVerify.service";
import {CurrencyService} from "../../modules/currency/currency.service";
import {PartnerService} from "../../service/partner.service";
import {SENETPRO_REDEEM_METHODS} from "../../pages/perks/perks.config";
import {AUTH_CODES} from "../../config/auth_codes";

@Component({
    selector: 'app-popup-withdraw-bank',
    templateUrl: './popup-withdraw-bank.component.html',
    styleUrls: ['./popup-withdraw-bank.component.scss']
})
export class PopupWithdrawBankComponent implements OnInit {
    @Input() popupInfo: any = {show: false};
    @Output('onClose') emitClose: EventEmitter<any> = new EventEmitter();
    @Output('onSuccess') onSuccess: EventEmitter<any> = new EventEmitter();

    SENETPRO_REDEEM_METHODS = SENETPRO_REDEEM_METHODS;
    form: FormGroup;
    formLegal: FormGroup;
    formDeliverySpeed: FormGroup;

    selectedRoutingNumber;
    routingList;
    moreThenAvailablePayout = false;
    moreThenAvailableText = '';

    deliveryPercent;
    deliveryAmount;
    sameDayShow = false;
    showRadioStep = false;
    showLegalForm = false;

    amountMin = 0;
    amountMax;
    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private languageService: LanguageService,
                private phoneVerifyService: PhoneVerifyService,
                private partnerService: PartnerService,
                private popupComponent: PopupComponent,
                private currencyService: CurrencyService,
                private cardsService: CardsService) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            routingNumber: [null, Validators.required],
            accountNumber: [null, Validators.required],
            routingNumberSelect: [null, Validators.required],
            retype_accountNumber: [null, Validators.required],
            amount: [null, Validators.required],
        });

        this.formLegal = this.formBuilder.group({
          legalFirstName: [null, Validators.required],
          legalLastName: [null, Validators.required],
        });

      this.formDeliverySpeed = this.formBuilder.group({
        speed: ['nextDay', Validators.required]
      });

        this.getSavedInstitution();
    }

    ngOnChanges(changes) {
        this.resetPopup();
    }

    ngOnDestroy() {
        this.resetPopup();
    }

    closePopup() {
        this.resetPopup();
        this.emitClose.emit();
    }

    getSavedInstitution() {
      const method = this.popupInfo.type;
      this.cardsService.getSavedInstitutions(method).subscribe(data => {
        try {
          if (data.status) {
            const available = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
            this.sameDayShow = data.sameDayShow;
            this.amountMax = data.amountMax ? data.amountMax : available;
            this.amountMin = data.amountMin ? data.amountMin : 1;
            if (data.sameDayShow) {
              this.showRadioStep = true;
              this.deliveryPercent = data.sameDay.fees.percent;
              this.deliveryAmount = data.sameDay.fees.amount;
            }
            this.chooseInstitution(data);
          } else {
            if (data.code && (data.code === AUTH_CODES.addLegalInfo)) {
              this.showLegalForm = true;
            } else {
              this.popupComponent.showPopupError({text: data.message});
              this.closePopup();
            }
          }
        } catch (e) {
          this.loaderService.hideAndShowTryAgain();
          this.closePopup();
        }
      }, () => {
        this.loaderService.hideAndShowTryAgain();
        this.closePopup();
      }, () => {
        this.loaderService.hide();
      });
    }

    onContinue() {
        const amount = this.form.controls.amount.value;
        const routingNumber = this.selectedRoutingNumber.number;
        const routingNumberValue = (document.querySelector('input[formcontrolname="routingNumber"]') as HTMLInputElement).value;
        const accountNumber = this.form.controls.accountNumber.value;
        const accountNumberValue = (document.querySelector('input[formcontrolname="accountNumber"]') as HTMLInputElement).value;
        const retypeAccountNumber = this.form.controls.retype_accountNumber.value;

        if (accountNumber === retypeAccountNumber) {
            const text = `${this.languageService.getStringFirstCharCapital('transfersData', 'youAreAboutToWithdraw')} 
                    ${this.currencyService.formatCurrency(amount)} 
                    ${this.languageService.getString('transfersData', 'toYour')} 
                    ${this.popupInfo.name.charAt(0).toUpperCase() + this.popupInfo.name.toLowerCase().slice(1)} <br><br>
                    <table style="text-align: left">
                        <tr>
                            <td style="padding-right: .5rem; padding-bottom: .5rem">${this.languageService.getString('formData', 'routingNumber')}:</td>
                            <td style="padding-bottom: .5rem"><span>${routingNumberValue}</span></td>
                        </tr>
                        <tr>
                            <td style="padding-right: .5rem; padding-bottom: .5rem">${this.languageService.getString('formData', 'accountNumber')}:</td>
                            <td style="padding-bottom: .5rem"><span>${accountNumberValue}</span></td>
                        </tr>
                        <tr>
                            <td style="padding-right: .5rem; padding-bottom: .5rem">${this.languageService.getString('deliveryFee')}:</td>
                            <td style="padding-bottom: .5rem">
                                <span>${this.getFees() ? '$' + this.getFees() : this.languageService.getStringFirstCharCapital('free')}</span>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding-right: .5rem; padding-bottom: .5rem">${this.languageService.getString('nftPopupData', 'totalDue')}:</td>
                            <td style="padding-bottom: .5rem"><span>$${this.getTotal()}</span></td>
                        </tr>
                    </table>
                    ${this.languageService.getStringFirstCharCapital('pleaseConfirm')}`;
            this.popupComponent.showPopupConfirmCancel({text}, (status) => {
                if (status) {
                    this.phoneVerifyService.repeatFunction = this.redeemViaPaynoteDirect.bind(this, amount, routingNumber, accountNumber);
                    this.redeemViaPaynoteDirect(amount, routingNumber, accountNumber)
                }
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }


    redeemViaPaynoteDirect(amount, routingNumber, accountNumber) {
      const method = this.sameDayShow ? this.formDeliverySpeed.controls.speed.value : null;
      this.cardsService.redeemViaPaynoteDirect(amount, routingNumber, accountNumber, method).subscribe(data => {
          this.onRedeemResult(data);
      }, () => {
          this.loaderService.hideAndShowTryAgain();
      }, () => {
          this.loaderService.hide();
      });
    }

    onBack() {
      if (this.deliveryAmount) {
        this.showRadioStep = true;
      } else {
        this.closePopup();
      }
    }

    onRedeemResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.onSuccess.emit();
                this.closePopup();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    resetPopup() {
        if (this.form) {
            this.form.reset();
        }
        this.selectedRoutingNumber = null;
        this.routingList = null;
    }

    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount);
    }

    getPartnerLogo() {
        return this.partnerService.getPartnerLogo() ? this.partnerService.getPartnerLogo() : '../assets/images/logo.png';
    }

    onRoutingNumberInput($event) {
        const val = $event.target.value;
        const inputVal = this.form.controls.routingNumber.value;
        if (val.length >= 3) {
            this.cardsService.getRoutingList(this.popupInfo.type, val).subscribe(data => {
                try {
                    if (data.status) {
                        this.routingList = data.result;
                        if (inputVal.length >= 9) {
                          if (data.result.length === 1 && (data.result[0].number === inputVal)) {
                            this.selectRoutingNumber(data.result[0]);
                          }
                        }
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                        //this.closePopup();
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                }
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    // onRoutingNumberChange($event) {
    //     this.form.controls.routingNumber.setValue($event.number);
    // }

    selectRoutingNumber(item) {
        this.selectedRoutingNumber = item;
        this.form.controls.routingNumber.setValue(item.number);
    }

    onInputAmount() {
        const avaliable = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
        const avaliableAndMax = avaliable <= this.amountMax ? avaliable : this.amountMax;

        const entered = Number.parseFloat(this.form.controls.amount.value);

        if (avaliableAndMax < entered) {
            this.form.controls.amount.setValue(String(avaliableAndMax));
            this.moreThenAvailablePayout = true;
            if (avaliableAndMax === this.amountMax) {
              this.moreThenAvailableText = this.languageService.getStringFirstCharCapital('max') + ' ' + this.languageService.getString('available') + ' ' + this.formatCurrency(avaliableAndMax);
            } else {
              this.moreThenAvailableText = this.languageService.getString('available') + ' ' + this.formatCurrency(avaliableAndMax);
            }
        } else {
          if (entered < this.amountMin) {
            this.moreThenAvailablePayout = true;
            this.moreThenAvailableText = this.languageService.getStringFirstCharCapital('min') + ' ' + this.languageService.getString('available') + ' ' + this.formatCurrency(this.amountMin);
          } else {
            this.moreThenAvailablePayout = false;
            this.moreThenAvailableText = '';
          }
        }
    }


    chooseInstitution(item) {
      if (item && item.routingNumber && item.acountNumber && item.bank) {
        this.form.controls.routingNumber.setValue(item.routingNumber);
        this.form.controls.accountNumber.setValue(item.acountNumber);
        this.form.controls.retype_accountNumber.setValue(item.acountNumber);
        this.selectedRoutingNumber = {};
        this.selectedRoutingNumber.number = item.routingNumber;
        this.selectedRoutingNumber.institution_name = item.bank;
      }
      this.showLegalForm = false;
    }

    onContinueLegal() {
      const legalFirstName = this.formLegal.controls.legalFirstName.value;
      const legalLastName = this.formLegal.controls.legalLastName.value;

      this.cardsService.sendLegalData(legalFirstName, legalLastName).subscribe(data => {
        try {
          if (data.status) {
            this.formLegal.reset();
            this.getSavedInstitution();
          } else {
            this.popupComponent.showPopupError({text: data.message});
            this.loaderService.hide();
          }
        } catch (e) {
          this.loaderService.hideAndShowTryAgain();
        }
      }, () => {
        this.loaderService.hideAndShowTryAgain();
      });
    }

    onRoutingNumberChange() {
        if (this.selectedRoutingNumber.number !== this.form.controls.routingNumber.value) {
          this.selectedRoutingNumber = null;
        }
    }

    isContinuePaymentButtonDisabled() {
      return !+this.form.controls.amount.value ||
             !this.selectedRoutingNumber ||
             (+this.form.controls.amount.value < this.amountMin) ||
             (this.form.controls.accountNumber.value !== this.form.controls.retype_accountNumber.value) ||
             ((this.form.controls.accountNumber.value.length < 8) || (this.form.controls.accountNumber.value.length > 15));
    }

    onRadioContinue() {
        this.showRadioStep = false;
    }

    onRadioBack() {
      this.closePopup();
    }

    getFees(): number {
        if ((this.formDeliverySpeed.controls.speed.value !== 'nextDay') && this.form && this.form.controls.amount.value && this.deliveryPercent && this.deliveryAmount) {
          return +((+this.form.controls.amount.value / 100 * this.deliveryPercent) + this.deliveryAmount).toFixed(2);
        }

        return 0;
    }

    getTotal(): number {
        if (this.form && this.form.controls.amount.value) {
          return +(+this.form.controls.amount.value - this.getFees()).toFixed(2);
        }
        return 0;
    }
}
